import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import i18n from "i18next";
import axios from 'axios';
import Footer from "./Footer";
import LiftUp from "./LiftUp";
import RouteChangeListener from "./RouteChangeListener";
import SeoBlock from "./SeoBlock";
import AuthRoute from "./partials/AuthRoute";
import PrivateRoute from "./partials/PrivateRoute";
import AuthHeader from "./partials/AuthHeader";
import AuthFooter from "./partials/AuthFooter";
import Loader from "./partials/Loader";
import {setAxiosSettings} from "../helper";

import { withTranslation } from "react-i18next";
const Direction = React.lazy(() => import('./Direction'));
const Section = React.lazy(() => import('./Section'));
const Catalog = React.lazy(() => import('./Catalog'));
const Brands = React.lazy(() => import('./Brands'));
const BrandPage = React.lazy(() => import('./BrandPage'));
const NotFound = React.lazy(() => import('./NotFound'));
const Welcome = React.lazy(() => import('./Welcome'));
const SettingsModal = React.lazy(() => import('./SettingsModal'));
const HeaderContainer = React.lazy(() => import('../containers/HeaderContainer'));
const ProductPageContainer = React.lazy(() => import('../containers/ProductPageContainer'));
const ComparisonListsContainer = React.lazy(() => import('../containers/ComparisonListsContainer'));
const CompareContainer = React.lazy(() => import('../containers/CompareContainer'));
const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('./pages/ResetPasswordPage'));
const NewPasswordPage = React.lazy(() => import('./pages/NewPasswordPage'));
const PersonalDataCabinetPage = React.lazy(() => import('./pages/cabinet/PersonalDataCabinetPage'));
const ManagerDataCabinetPage = React.lazy(() => import('./pages/cabinet/ManagerDataCabinetPage'));
const CompanyDataCabinetPage = React.lazy(() => import('./pages/cabinet/CompanyDataCabinetPage'));
const OrdersCabinetPage = React.lazy(() => import('./pages/cabinet/OrdersCabinetPage'));
const SelectionsCabinetPage = React.lazy(() => import('./pages/cabinet/SelectionsCabinetPage'));
const OneSelectionCabinetPage = React.lazy(() => import('./pages/cabinet/OneSelectionCabinetPage'));
const SearchSelectionPage = React.lazy(() => import('./pages/cabinet/SearchSelectionPage'));
const CartSelection = React.lazy(() => import('./partials/CartSelection'));
const DuplicationSelectionModal = React.lazy(() => import('./partials/DuplicationSelectionModal'));

// We need to clear sessionStorage at App start if user uncheck remember me for proper behaviour (page reload/duplication, open in new tab)
sessionStorage.removeItem('authToken');

const token = localStorage.getItem('authToken');
const locale = localStorage.getItem('userLocale') || 'ua';

if(token) {
    // Global axios settings for further requests
    setAxiosSettings(token, locale)
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seoData: {}
        };

        this.setSeo = this.setSeo.bind(this);
        this.setAxiosLangSettings = this.setAxiosLangSettings.bind(this);
    }

    componentDidMount() {
        this.props.i18n.on('languageChanged', this.setAxiosLangSettings);
    }

    componentWillUnmount() {
        this.props.i18n.off('languageChanged', this.setAxiosLangSettings);
    }

    setSeo(seoData) {
        this.setState({seoData});
    }

    setAxiosLangSettings() {
        axios.interceptors.request.use(function (config) {
            config.headers.Lang =  i18n.language;

            return config;
        });
    }

    render() {
        return (
            <Fragment>
                <SeoBlock seo={this.state.seoData} />
                <React.Suspense fallback={<Loader/>}>
                    <Router>
                        <RouteChangeListener>
                            {(localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) ?
                                <HeaderContainer />
                                :
                                <AuthHeader/>
                            }
                            <main className="flex-grow-1">
                                <Switch>
                                    <AuthRoute path="/login" component={LoginPage} seoCallback={this.setSeo} />
                                    <AuthRoute path="/reset-password" component={ResetPasswordPage} seoCallback={this.setSeo} />
                                    <AuthRoute path="/new-password" component={NewPasswordPage} seoCallback={this.setSeo} />
                                    <PrivateRoute exact path="/" component={Welcome} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/direction/:slug" component={Direction} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/section/markdown/:slug" component={Section} isMarkdown={true} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/section/:slug" component={Section} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/catalog/markdown/:slug" component={Catalog} pageType="catalog" isMarkdown={true} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/catalog/:slug" component={Catalog} pageType="catalog" seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/search" component={Catalog} pageType="search" seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/product/markdown/:slug" component={ProductPageContainer} isMarkdown={true} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/product/:slug" component={ProductPageContainer} seoCallback={this.setSeo}/>
                                    <PrivateRoute exact path="/comparison-lists" component={ComparisonListsContainer} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/compare/:slug" component={CompareContainer} seoCallback={this.setSeo}/>
                                    <PrivateRoute exact path="/brands" component={Brands} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/brand/:slug" component={BrandPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/personal-data" component={PersonalDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/manager-data" component={ManagerDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/company-data" component={CompanyDataCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/orders" component={OrdersCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selections" component={SelectionsCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selection" component={OneSelectionCabinetPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/selections-search" component={SearchSelectionPage} seoCallback={this.setSeo}/>
                                    <PrivateRoute path="/cabinet/favorites" component={Catalog} pageType="favorites" seoCallback={this.setSeo}/>
                                    <Route path="/404" render={routeProps => <NotFound {...routeProps} seoCallback={this.setSeo} />}></Route>
                                    <Redirect to="/404" />
                                </Switch>
                            </main>
                            {(localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) ?
                                <Fragment>
                                    <LiftUp isInner={false} />
                                    <CartSelection />
                                    <DuplicationSelectionModal />
                                    <SettingsModal />
                                    <Footer />
                                </Fragment>
                                :
                                <AuthFooter/>
                            }
                        </RouteChangeListener>
                    </Router>
                </React.Suspense>
            </Fragment>
        );
    }
}

export default withTranslation()(App);