import React, { Component } from "react";

class LiftUp extends Component{
    constructor(props) {
        super(props);

        this.state = {show: false};

        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollUp = this.handleScrollUp.bind(this);
    }

    componentDidMount() {
        // Add scroll listener
        if (this.props.isInner) {
            document.querySelector('.lift-up').parentElement.addEventListener('scroll', this.handleScroll);
        } else {
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        // Remove scroll listener
        document.querySelector('.lift-up').parentElement.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('scroll', this.handleScroll);
    }

    // a lot of повторов
    handleScroll() {
        const scrollTop = window.pageYOffset;
        const scrollTopInner = document.querySelector('.lift-up').parentElement.scrollTop;

        if (this.props.isInner) {
            if (scrollTopInner >= 700 ) {
                if (!this.state.show) {
                    this.setState({show: true});
                }
            } else {
                if (this.state.show) {
                    this.setState({show: false});
                }
            }
        } else {
            if (scrollTop >= 700 && scrollTop + window.innerHeight < document.querySelector('footer').offsetTop) {
                if (!this.state.show) {
                    this.setState({show: true});
                }
            } else {
                if (this.state.show) {
                    this.setState({show: false});
                }
            }
        }
    }

    handleScrollUp() {
        if (this.props.isInner) {
            document.querySelector('.lift-up').parentElement.scrollTo({top: 0, behavior: 'smooth'});
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    render() {
        return (
            <div className={`lift-up position-fixed pointer bg-white ${this.state.show ? 'd-flex' : 'd-none'}`} onClick={this.handleScrollUp}></div>
        )
    }
}

export default LiftUp;