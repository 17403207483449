import React from "react";
import { Helmet } from "react-helmet";

function SeoBlock(props) {
    const { seo } = props;
    let tags = [];

    seo.tag && seo.tag.map(tag => {
        tags.push({name: tag.name, content: tag.value,})
    });

    return (
        <Helmet title={seo.title || 'MIRS Catalog'} meta={tags} />
    );
}

export default SeoBlock;