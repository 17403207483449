import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class RouteChangeListener extends Component {
    constructor(props) {
        super(props);

        // Global route change listener
        this.props.history.listen((location, action) => {
            const cartModal = document.getElementById('cartModal');
            if(cartModal && cartModal.classList.contains('show')) {
                document.getElementById('cart-modal-close').click();
            }

            // SettingsModal
            const settingsModal = document.getElementById('settings-modal');
            if(settingsModal && settingsModal.classList.contains('show')) {
                document.getElementById('settings-modal-close').click();
            }

            const searchClear = document.getElementById('header-search-clear');
            if(searchClear) {
                if(location.pathname !== '/search') {
                    searchClear.click();
                }

                document.getElementById('header-search-result').classList.add('no-result');
            }
        });
    }

    render() {
        return (
            <div className="d-flex flex-column vh-100 position-relative">
                {this.props.children}
            </div>
        );
    }
}

export default withRouter(RouteChangeListener);