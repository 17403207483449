import React from "react";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

export default function ExchangeWidget() {
    const { t } = useTranslation();
    const exchangeData = useSelector(state => state.getExchangeData.exchangeData, shallowEqual);

    return (
        <>
            {Object.keys(exchangeData).length > 0 &&
                <div className="exchange-widget">
                    <div className="exchange-widget__cap">{t('exchangeWord')}</div>
                    <div className="d-flex">
                        <span>$ {exchangeData.usd}</span>
                        <span>€ {exchangeData.eur}</span>
                    </div>
                </div>
            }
        </>
    );
}
