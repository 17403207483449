import React from 'react';
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next";

export default function AuthFooter(props) {
    const { t } = useTranslation();

    return (
        <footer id="main-footer" className="border-top">
            <div className="page-container d-none d-xl-block mt-4 mb-3 mb-xl-4 py-xl-3">
                <div className="d-flex justify-content-center">
                    <Link to="/">
                        <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo float-md-right"/>
                    </Link>
                </div>
            </div>
            <div className="bg-light text-center footer-rights">© {new Date().getFullYear()} {t('copyright')} </div>
        </footer>
    )
}
