import React from "react";
import {applyMiddleware, createStore} from "redux";
import catalogApp from "./js/reducers";
import thunkMiddleware from "redux-thunk";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "./js/app.js"
import App from "./js/components/App";
import "./js/components/translation/i18n";
import "./sass/app.scss"

const store = createStore(catalogApp, applyMiddleware(thunkMiddleware));

if (document.getElementById('root')) {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}