import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LOCALE } from "../../constant";

import uaTranslation from "./ua/translation.json";
import ruTranslation from "./ru/translation.json";

const resources = {
    ua: {
        translation: uaTranslation
    },
    ru: {
        translation: ruTranslation
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('userLocale') || DEFAULT_LOCALE,
        fallbackLng: DEFAULT_LOCALE,

        keySeparator: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
