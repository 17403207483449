import React from 'react';
import { Link } from 'react-router-dom';

export default function AuthHeader() {
    return (
        <header className="auth-header bg-white">
            <div className="page-container">
                <div className="d-flex justify-content-center justify-content-xl-between align-items-center mt-3">
                    <Link to="/">
                        <img src="/images/logo-mirs.svg" alt="MIRS logo" className="header-logo"/>
                    </Link>
                </div>
            </div>
        </header>
    )
}