import { combineReducers } from "redux";
import {
    UPDATE_CART,
    CHANGE_NEEDS_UPDATE,
    UPDATE_CART_COUNTER,
    ADD_PRODUCT_ID,
    UPDATE_COMPARE,
    CHANGE_REQUEST_FLAG_COMPARE,
    UPDATE_COMPARE_COUNTER,
    ADD_PRODUCT_ID_TO_COMPARE,
    ADD_PRODUCT_ID_TO_FAVORITE,
    REMOVE_PRODUCT_ID_FROM_FAVORITE,
    CHANGE_REQUEST_FLAG_CART,
    UPDATE_CREATE_RENAME_MODAL,
    UPDATE_SELECTION,
    GET_CUSTOMERS,
    GET_CUSTOMER_PROJECTS,
    SELECTED_PARTNER,
    SELECTED_PROJECT,
    SELECTED_SELECTION,
    UPDATE_CART_SELECTION_COUNTER,
    GET_CART_SELECTION,
    UPDATE_MANAGER_ID,
    PRICE_NEED_UPDATE,
    GET_PRODUCT_ID_CART_SELECTION,
    DUPLICATE_SELECTION,
    NEED_UPDATE_SELECTIONS_PAGE,
    NEW_PROJECT_IN_DUPLICATE_SELECTION,
    CUSTOMERS_REQUEST_LOADER, EXCHANGE_DATA, GET_DIRECTIONS_DATA, GET_CURRENCY
} from "../actions";
import {storagePartner, storageProject, storageSelection} from "../constant";

const initialCartState = {
    cartCounter: 0,
    needsUpdate: true,
    productGroups: [],
    productIds: {},
    cartTotal: 0,
    requestFlag: false
};

const initialCompareState = {
    compareCounter: 0,
    requestFlag: true,
    productGroups: [],
    productIds: {}
};

const initialFavoritesState = {
    productIds: []
};

function cart(state = initialCartState, action) {
    switch (action.type) {
        case UPDATE_CART:
            return Object.assign({}, state, {
                productGroups: action.productGroups,
                productIds: action.productIds,
                cartTotal: action.cartTotal
            });
        case CHANGE_NEEDS_UPDATE:
            return Object.assign({}, state, {
                needsUpdate: action.flag
            });
        case UPDATE_CART_COUNTER:
            return Object.assign({}, state, {
                cartCounter: action.cartCounter
            });
        case ADD_PRODUCT_ID:
            const newProductIds = Object.assign({}, state.productIds, {
                [action.addedId]: {count: 1}
            });
            return Object.assign({}, state, {
                productIds: newProductIds
            });
        case CHANGE_REQUEST_FLAG_CART:
            return Object.assign({}, state, {
                requestFlag: action.flag
            });
        default:
            return state;
    }
};

function compare(state = initialCompareState, action) {
    switch (action.type) {
        case UPDATE_COMPARE:
            return Object.assign({}, state, {
                productGroups: action.productGroups,
                productIds: action.productIds
            });
        case CHANGE_REQUEST_FLAG_COMPARE:
            return Object.assign({}, state, {
                requestFlag: action.flag
            });
        case UPDATE_COMPARE_COUNTER:
            return Object.assign({}, state, {
                compareCounter: action.compareCounter
            });
        case ADD_PRODUCT_ID_TO_COMPARE:
            const newProductIds = Object.assign({}, state.productIds, {
                [action.addedId]: {count: 1}
            });
            return Object.assign({}, state, {
                productIds: newProductIds
            });
        default:
            return state;
    }
};

function favoriteReducer(state = initialFavoritesState, action) {
    switch (action.type) {
        case ADD_PRODUCT_ID_TO_FAVORITE:
            return { productIds: [...state.productIds, action.addedId] };
        case REMOVE_PRODUCT_ID_FROM_FAVORITE:
            return { productIds: state.productIds.filter(productId => productId !== action.idToRemove)};
        default:
            return state;
    }
}

const initialCreateRenameModalState = {
    data: {}
};

function createRenameModal(state = initialCreateRenameModalState, action) {
    switch (action.type) {
        case UPDATE_CREATE_RENAME_MODAL:
            return Object.assign({}, state, {
                data:  action.data,
            });
        default:
            return state;
    }
};

const initialUpdateSelectionsBlockState = {
    needUpdate: false,
    partnerId: '',
    projectId: ''
};

function updateSelectionsBlock(state = initialUpdateSelectionsBlockState, action) {
    switch (action.type) {
        case UPDATE_SELECTION:
            return Object.assign({}, state, {
                needUpdate:  action.needUpdate,
                partnerId:  action.partnerId,
                projectId:  action.projectId,
            });
        default:
            return state;
    }
};

const initialUpdateSelectionsPageState = {
    needUpdate: false
};

function needUpdateSelectionsPage(state = initialUpdateSelectionsPageState, action) {
    switch (action.type) {
        case NEED_UPDATE_SELECTIONS_PAGE:
            return Object.assign({}, state, {
                needUpdate:  action.needUpdate
            });
        default:
            return state;
    }
};

const initialManagerIdState = {
    managerId: localStorage.getItem('managerId') ? localStorage.getItem('managerId') : '',
};

function getManagerId(state = initialManagerIdState, action) {
    switch (action.type) {
        case UPDATE_MANAGER_ID:
            return Object.assign({}, state, {
                managerId:  action.managerId
            });
        default:
            return state;
    }
};

const initialCustomersState = {
    customersLoader: false,
    customers: []
};

function customers(state = initialCustomersState, action) {
    switch (action.type) {
        case CUSTOMERS_REQUEST_LOADER:
            return Object.assign({}, state, {
                customersLoader:  action.customersLoader
            });
        case GET_CUSTOMERS:
            return Object.assign({}, state, {
                customers:  action.customers
            });
        default:
            return state;
    }
};

const initialSelectedOptionsState = {
    priceNeedUpdate: false,
    globalPartner: storagePartner ? JSON.parse(storagePartner) : {},
    globalProject: storageProject ? JSON.parse(storageProject) : {},
    globalSelection: storageSelection ? JSON.parse(storageSelection) : {},
    globalPartnerProjects: []
};

function selectedOptions(state = initialSelectedOptionsState, action) {
    switch (action.type) {
        case PRICE_NEED_UPDATE:
            return Object.assign({}, state, {
                priceNeedUpdate: action.priceNeedUpdate
            });
        case SELECTED_PARTNER:
            return Object.assign({}, state, {
                globalPartner: action.globalPartner
            });
        case SELECTED_PROJECT:
            return Object.assign({}, state, {
                globalProject: action.globalProject
            });
        case SELECTED_SELECTION:
            return Object.assign({}, state, {
                globalSelection: action.globalSelection
            });
        case GET_CUSTOMER_PROJECTS:
            return Object.assign({}, state, {
                globalPartnerProjects:  action.globalPartnerProjects
            });
        default:
            return state;
    }
};

const initialDuplicateState = {
    selectionId: '',
    selectionName: '',
    orderId: null,
    newProjectInDuplicate: {},
};

function duplicateSelection(state = initialDuplicateState, action) {
    switch (action.type) {
        case DUPLICATE_SELECTION:
            return Object.assign({}, state, {
                selectionId: action.selectionId,
                selectionName: action.selectionName,
                orderId: action.orderId
            });
        case NEW_PROJECT_IN_DUPLICATE_SELECTION:
            return Object.assign({}, state, {
                newProjectInDuplicate: action.newProjectInDuplicate
            });
        default:
            return state;
    }
};

const initialCartSelectionState = {
    counter: 0,
    cartSelection: {},
    productsId: []
};

function cartSelection(state = initialCartSelectionState, action) {
    switch (action.type) {
        case UPDATE_CART_SELECTION_COUNTER:
            return Object.assign({}, state, {
                counter: action.counter
            });
        case GET_CART_SELECTION:
            return Object.assign({}, state, {
                cartSelection: action.cartSelection
            });
        case GET_PRODUCT_ID_CART_SELECTION:
            return Object.assign({}, state, {
                productsId: action.productsId
            });
        default:
            return state;
    }
};

const initialExchangeDataState = {
    exchangeData: {},
};

function getExchangeData(state = initialExchangeDataState, action) {
    switch (action.type) {
        case EXCHANGE_DATA:
            return Object.assign({}, state, {
                exchangeData:  action.exchangeData
            });
        default:
            return state;
    }
};

const initialDirectionsDataState = {
    menuData: [],
    isDishesUser: false,
};

function getDirectionsData(state = initialDirectionsDataState, action) {
    switch (action.type) {
        case GET_DIRECTIONS_DATA:
            return Object.assign({}, state, {
                menuData:  action.menuData,
                isDishesUser: action.isDishesUser
            });
        default:
            return state;
    }
};

const initialCurrencyState = '';

function getCurrencyReducer(state = initialCurrencyState, action) {
    switch (action.type) {
        case GET_CURRENCY:
            return action.currency;
        default:
            return state;
    }
}

const catalogApp = combineReducers({
    cart,
    compare,
    favoriteReducer,
    createRenameModal,
    updateSelectionsBlock,
    needUpdateSelectionsPage,
    getManagerId,
    customers,
    selectedOptions,
    cartSelection,
    duplicateSelection,
    getCurrencyReducer,
    getExchangeData,
    getDirectionsData
});

export default catalogApp;
